<template>

    <div class="CEESAR-ComboBox-DropDown">
        <label class="CEESAR-ComboBox-DropDownLabel CEESAR-AlignLabel">
            {{label}}
        </label>

        <div :class="['CEESAR-DropDown', isExpanded? 'CEESAR-DropDown-Expanded' : '']"
                v-on-click-outside="HideContent">
                    
            <div class="CEESAR-DropDownButton" v-on:click="ToggleShowContent">
                <div class="CEESAR-DropDownButtonLabel">
                    {{selectionLabel}}
                </div>
            </div>

            <div class="CEESAR-DropDownContentContainer">
                <div :class="['CEESAR-DropDownContent']">
                    <CEESAR-Button v-for="entry in entries" v-bind:key="entry.id"
                        :label="entry.label" v-on:click="setSelectedId(entry.id)"/>
                </div>
            </div>
        </div>        
    </div>

</template>

<script>
export default {
    name:"CEESAR-ComboBox-DropDown",
    props:{
        label: {type: String, default: "" },
        entries: {type: Array, default: []},
        value: {type: Number, default: ""},
        horizontal: {type: Boolean, default: false},
        required: {type: Boolean, default: false}
    },
    data(){
        return{
            isExpanded: false,
            selectedId: this.value
        };
    },
    computed:{
        selectionLabel(){
            let selection = this.entries.find(x => x.id == this.selectedId);
            if(selection != undefined)
                return selection.label;
            return "<>";
        }
    },
    methods:{
        ToggleShowContent(e){
            this.isExpanded = !this.isExpanded;
            e.stopPropagation();
        },
        HideContent(){
            this.isExpanded = false;
        },
        setSelectedId(pId){
            this.$emit("change", pId);
            this.selectedId = pId;
            this.HideContent();
        }
    }
}
</script>

<style lang="less">

</style>